<template>
  <div class="d-flex justify-content-center align-items-center px-3 px-sm-0">
    <Card class="requested__card mt-7">
      <SectionHeading class="mb-3" :heading="$t('requested.heading')" />
      <i18n path="requested.text" class="request__text mb-sm-6 mb-4" tag="p">
        <br />
      </i18n>

      <Timeline :steps="steps" class="mb-sm-7 mb-4 steps__requested"/>
      <BButton
        @click="$router.push('/listings/saved')"
        variant="primary"
        class="d-block mx-auto"
      >
        {{$t('requested.submitBtn')}}
      </BButton>
    </Card>
  </div>
</template>

<script>
import Card from "../components/general/Card.vue";
import SectionHeading from "../components/general/SectionHeading.vue";
import Timeline from "../components/general/Timeline.vue";
export default {
  components: { Card, SectionHeading, Timeline },
  computed:{
    steps(){
      const stepsLocale = this.$t('requested.list')
      return stepsLocale.map((step,i)=>({text:step,completed:i === 0 ? true : false}))
    }
  }
};
</script>

<style lang="scss" scoped>
.requested {
  &__text {
    @include font(1.125rem, $gray3);
  }
}

.steps {
  &__requested{
      max-width: 87%;
  }

}
</style>